import React from 'react';
import { View,StyleSheet,Pressable, Text } from 'react-native';

type ButtonProps={
    onPress:()=>void;
    title:string
}


export const BaseButton:React.FC<ButtonProps> =({onPress,title})=>{
    return(
        <Pressable
            style={styles.buttonStyle}
            onPress={onPress}
        >
        <Text style={styles.buttonText}>{title}</Text>
        </Pressable>
    )
}

export default BaseButton;


const styles = StyleSheet.create({
    buttonStyle: {
        backgroundColor: "#2525ff",
        width: '100%',
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 50
    },
    buttonText: {
        color: "white",
        fontWeight: "bold",
        fontSize:16
    }
});