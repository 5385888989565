import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import AdjustPoint from './AdjustPoint';

type DeductPointProps={
    key:number
    point: number
    explanation: string
}

export const DeductPoint:React.FC<DeductPointProps> =({key, point, explanation })=>{
    return(
        <AdjustPoint
            index={key}
            status='－'
            adjustTitle='減点ポイント'
            point={Math.abs(point)}
            explanation={explanation}
            CustomStyleStatus={styles.deduct}
            CustomStyleAdjustText={styles.deductTitle}
        ></AdjustPoint>
    )
}

export default DeductPoint;

const styles = StyleSheet.create({
    deduct: {
        backgroundColor: '#DC3545'
    },
    deductTitle: {
        color: '#DC3545',
        fontWeight: 600,
    }
});