import React, { useState } from 'react';
import { View, TextInput, Button, Text, StyleSheet, Alert } from 'react-native';
import axios from 'axios';
import { HomeLayout } from "../template/HomeLayout"
import BaseButton from '../atoms/BaseButton';
import TextInputArea from '../atoms/TextInputArea';
interface LoginScreenProps {
    navigation: any;  // navigation の型は適切に設定
    setIsLoggedIn: (loggedIn: boolean) => void;  // setIsLoggedIn プロパティの型を追加
}

const LoginScreen: React.FC<LoginScreenProps> = ({ navigation, setIsLoggedIn }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const [inputText, setInputText] = useState<string>('');

    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [loginError, setLoginError] = useState('');

  const handleLogin = async () => {
    // バリデーションエラーのリセット
    setUsernameError('');
    setPasswordError('');
    setLoginError('');

    let isValid = true;

    // ユーザ名の入力チェック
    if (!username) {
      setUsernameError('必須項目です');
      isValid = false;
    }

    // パスワードの入力チェック
    if (!password) {
      setPasswordError('必須項目です');
      isValid = false;
    }

    // バリデーションエラーがある場合はログイン処理を中断
    if (!isValid) {
      return;
    }
    setLoading(true);
    try {
        const response = await axios.post('http://127.0.0.1:8000/accounts/login/', {
            username,
            password,
        });
        if (response.status === 200) {
            Alert.alert('ログイン成功', '正常にログインしました');
            navigation.navigate('Home');
        }
    } catch (error) {
      setLoginError('ユーザー名またはパスワードが正しくありません');
    } finally {
        setLoading(false);
    }
  };

    const handleTextChange = (text: string) => {
        setInputText(text);
    };


  return (
    <HomeLayout
        title="ログイン"
        children={
            <>
                <Text style={styles.title}>ログイン</Text>
                <TextInputArea
                    placeholder="ユーザー名"
                    value={username}
                    onTextChange={setUsername}
                ></TextInputArea>
                {usernameError ? <Text style={styles.errorText}>{usernameError}</Text> : null}
                <TextInputArea
                    placeholder="パスワード"
                    value={password}
                    onTextChange={setPassword}
                    secureTextEntry
                ></TextInputArea>
                {passwordError ? <Text style={styles.errorText}>{passwordError}</Text> : null}
                {loginError ? <Text style={styles.errorText}>{loginError}</Text> : null}
                <View style={styles.blankSpace} />
                <BaseButton
                    onPress={handleLogin}
                    title ={loading ? 'ログイン中...' : 'ログイン'}
                ></BaseButton>
            </>
        }
    >
    </HomeLayout>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    marginBottom: 30,
    textAlign: 'center',
    top: -50,
  },
  blankSpace: {
    height: 100,  
  },
  errorText: {
    color: 'red',
    marginBottom: 10,
    marginTop: -10,
  },
});

export default LoginScreen;
