import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import AdjustPoint from './AdjustPoint';

type IncompleteProps={
    explanation: string
}

export const Incomplete:React.FC<IncompleteProps> =({ explanation })=>{
    return(
        <AdjustPoint
            index={1}
            status='×'
            adjustTitle='解答不備要素'
            explanation={explanation}
            CustomStyleStatus={styles.incomplete}
            CustomStyleAdjustText={styles.incompleteTitle}
        ></AdjustPoint>
        
    )
}

export default Incomplete;

const styles = StyleSheet.create({
    incomplete: {
        backgroundColor: '#333'

    }, 
    incompleteTitle: {
        color: '#333',
        fontWeight: 600,
    },
});