import React, { useState } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import HomeScreen from "./components/pages/Home";
import SelectSchoolsScreen from "./components/pages/SelectSchools";
import SelectQuestionYearScreen from "./components/pages/SelectQuestionYear";
import SelectQuestionsScreen from "./components/pages/SelectQuestions";
import LoginScreen from "./components/pages/Login";
import { InputAnswerScreen } from "./components/pages/InputAnswer";
import ResultScreen from "./components/pages/Result";

export type Scoring={
  description:string,
  points:number
}

export type RootStackParamList = {
  Auth: undefined;
  Home: undefined;
  SelectSchools: undefined;
  SelectQuestionYear: { schoolId: number, schoolName: string };
  SelectQuestions: { schoolId: number, year: number, schoolName: string };
  InputAnswer: { questionId: number };
  Result: {
    questionTitle: string | undefined,
    schoolName: string | undefined,
    year: number,
    answerText: string | undefined,
    answer: string | undefined,
    advice:string | undefined,
    score:number | undefined,
    addition:Scoring[] | undefined,
    subtraction:Scoring[] | undefined,
    incomplete_answer:string | undefined,
  };
  Login: undefined;
};

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function App() {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false); // ログイン状態を管理
    return (
    <NavigationContainer>
        <Stack.Navigator initialRouteName="Login" screenOptions={{ headerShown: false }}>
            <Stack.Screen name="Login">
                {(props) => (<LoginScreen {...props} setIsLoggedIn={setIsLoggedIn} />)}
            </Stack.Screen>
            <Stack.Screen name="Home" component={HomeScreen}></Stack.Screen>
            <Stack.Screen name="SelectSchools" component={SelectSchoolsScreen}></Stack.Screen>
            <Stack.Screen name="SelectQuestionYear" component={SelectQuestionYearScreen}></Stack.Screen>
            <Stack.Screen name="SelectQuestions" component={SelectQuestionsScreen}></Stack.Screen>
            <Stack.Screen name="InputAnswer" component={InputAnswerScreen}></Stack.Screen>
            <Stack.Screen name="Result" component={ResultScreen}></Stack.Screen>
        </Stack.Navigator>
    </NavigationContainer>
    );
}
