import React, { useState, useEffect } from 'react';
import {Text, View, StyleSheet } from 'react-native';
import { BaseLayout } from "../template/BaseLayout"
import SelectBox from '../organisms/SelectBox';
import getSchools from '../../api/getSchool';
import BaseText from '../atoms/BaseText';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../App';

interface Schools {
    id:number;
    school_name:string;
}

type Props ={
    navigation:NativeStackNavigationProp<RootStackParamList, 'SelectSchools'>;
}

export const SelectSchoolsScreen:React.FC<Props> = ({navigation}) => {

    // 学校名一覧を格納
    const [schools,setSchools] = useState<Schools[]>([]);

    // エラーハンドリング用のstate
    const [error, setError] = useState<string | null>(null);

    // 学校名一覧APIの取得
    const fetchSchools = async()=>{
        try{
            const data = await getSchools();
            console.log(data)
            if (Array.isArray(data)) {
                setSchools(data);
            } else {
                setError('学校名が登録されておりません。');
            }
        } catch (err) {
            setError('学校名を取得できません。'); // エラーハンドリング
        }
    }

    useEffect(() => {
        fetchSchools();
    }, []);

    return (
        <>
            <BaseLayout title="答案の選択" status={0}>
                <BaseText>採点する問題を選択してください。</BaseText>
                {
                    error ? ( <Text>{error}</Text>)
                    :(
                        schools.length > 0?(
                            schools.map(school=>
                                <SelectBox
                                    key={school.id}
                                    title={school.school_name}
                                    onPress={() => navigation.navigate(
                                        'SelectQuestionYear',
                                        { schoolId:school.id, schoolName:school.school_name}
                                    )}
                                ></SelectBox>
                            )
                        ):(
                            // 学校一覧が空の場合のメッセージ
                            <Text style={styles.text}>学校名が登録されておりません。</Text>
                        )
                    )
                }
            </BaseLayout>
        </>
    );
};

export default SelectSchoolsScreen;

const styles = StyleSheet.create({
    text:{
        fontSize:16
    }
});