import React from 'react';
import { StyleSheet,Pressable, Text } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

type ButtonProps={
    onPress:()=>void;
    title:string
}


export const BackButton:React.FC<ButtonProps> =({onPress,title})=>{
    return(
        <Pressable
            style={styles.buttonStyles}
            onPress={onPress}>
            <FontAwesomeIcon style={styles.icon} icon={faChevronLeft} />
            <Text style={styles.buttonText}>{title}</Text>
        </Pressable>
    )
}

export default BackButton;

const styles = StyleSheet.create({
    buttonStyles:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },
    buttonText: {
        color: "#3366CC",
        fontWeight: "bold",
        fontSize:16
    },
    icon :{
        fontSize:16,
        color:"#3366CC"
    }
});