import React, { ReactNode } from 'react';
import { View, Text, SafeAreaView, StyleSheet } from 'react-native';
import BaseTitle from '../atoms/BaseTitle';
import SubHeading from '../atoms/SubHeading';
import MediumHeading from '../atoms/MediumHeading';
import AddPoint from '../atoms/AddPoint';
import DeductPoint from '../atoms/DeductPoint';
import Incomplete from '../atoms/Incomplete';
import { Scoring } from '../../App';
import BaseText from '../atoms/BaseText';


type ResultDetailProps={
    answerText: string
    additions:Scoring[],
    subtractions:Scoring[],
    incomplete_answers:string,
}

export const ResultDetail: React.FC<ResultDetailProps> = ({
    answerText,
    additions,
    subtractions,
    incomplete_answers}) => {

    return (
        <>
            <View style={styles.correctAnswer}>
                <MediumHeading
                    title={'模範解答'}
                ></MediumHeading>
                <Text>{answerText}</Text>
            </View>
            <View style={styles.AddPoint}>
                <MediumHeading title={'加点ポイント'}></MediumHeading>
                {
                    subtractions.length > 0?(additions.map((addition,index) =>
                        <AddPoint key={index} point={addition.points} explanation={addition.description}></AddPoint>
                    )):(
                        <BaseText>加点ポイントなし</BaseText>
                    )
                }

            </View>
            <View style={styles.DeductPoint}>
                <MediumHeading title={'減点ポイント'}></MediumHeading>
                {
                    subtractions.length > 0?(subtractions.map((subtraction,index) =>
                        <DeductPoint key={index} point={subtraction.points} explanation={subtraction.description}></DeductPoint>
                    )):(
                        <BaseText>減点ポイントなし</BaseText>
                    )
                }
            </View>
            <View>
                <MediumHeading title={'解答不備'}></MediumHeading>
                {
                    incomplete_answers ?(
                        <Incomplete explanation={incomplete_answers}></Incomplete>
                    ):(
                        <BaseText>解答不備なし</BaseText>
                    )
                }
            </View>
        </>

    );
};

export default ResultDetail;

const styles = StyleSheet.create({
    correctAnswer: {
        marginTop: 20,
        marginBottom: 40,
    },
    AddPoint: {
        marginBottom: 40,
    },
    DeductPoint: {
        marginBottom: 40,
    },
    // incomplete: {
    //     flexDirection: 'row',
    //     alignItems: 'flex-start',
    //     justifyContent: 'space-between',
    // },
});