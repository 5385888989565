import React, { ReactNode } from 'react';
import { View, Text, SafeAreaView, StyleSheet } from 'react-native';
import BaseTitle from '../atoms/BaseTitle';
import SubHeading from '../atoms/SubHeading';
import MediumHeading from '../atoms/MediumHeading';
import AddPoint from '../atoms/AddPoint';
import DeductPoint from '../atoms/DeductPoint';

type AdviceProps={
    adviceText: string
}

export const Advice: React.FC<AdviceProps> = ({ adviceText }) => {
    return (
        <>
            <Text>{adviceText}</Text>
        </>
        
    );
};

export default Advice;

const styles = StyleSheet.create({
    correctAnswer: {
        marginBottom: 40,
    },
    AddPoint: {
        marginBottom: 40,
    },
    DeductPoint: {
        marginBottom: 40,
    }
});