import axios from 'axios';

export const getYear = async (query: string)=>{
    try{
        const response = await axios.get(`http://127.0.0.1:8000/app/select-year/?${query}`);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching customers:", error);
        return []
    }
}

export default getYear;