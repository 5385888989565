import React from 'react';
import { StyleSheet, Text } from 'react-native';

type MediumHeadingProps={
    title:string
}


export const MediumHeading:React.FC<MediumHeadingProps> =({title})=>{
    return(
        <>
            <Text style={styles.mediumHeading}>{title}</Text>
        </>
    )
}

export default MediumHeading;

const styles = StyleSheet.create({
    mediumHeading:{
        fontSize:16,
        fontWeight:'bold',
        paddingBottom:8
    },
});