import React, { ReactNode } from 'react';
import { View,Text, Button,StyleSheet } from 'react-native';

type HomeLayoutProps={
    children:ReactNode,
    title:string,
}

export const HomeLayout :React.FC<HomeLayoutProps> = ({children,title})=>{
    return(
        <>
            <View style={styles.container}>
                {children}
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      paddingTop: 40,
      paddingRight: 20,
      paddingBottom: 40,
      paddingLeft: 20,
    },
    text:{
        fontSize:16
    }
});