import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import AdjustPoint from './AdjustPoint';

type AddPointProps={
    key: number
    point: number
    explanation: string
}

export const AddPoint:React.FC<AddPointProps> =({key, point, explanation })=>{
    return(
        <AdjustPoint
            index={key}
            status='＋'
            adjustTitle='加点ポイント'
            point={point}
            explanation={explanation}
            CustomStyleStatus={styles.add}
            CustomStyleAdjustText={styles.addTitle}
        ></AdjustPoint>

    )
}

export default AddPoint;

const styles = StyleSheet.create({
    add: {
        backgroundColor: '#36C'

    },
    addTitle: {
        color: '#36C',
        fontWeight: 600,
    },
});