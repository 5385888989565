import React from 'react';
import { StyleSheet, Text } from 'react-native';

type BaseTitleProps={
    title:string
}


export const BaseTitle:React.FC<BaseTitleProps> =({title})=>{
    return(
        <>
            <Text style={styles.title}>{title}</Text>
        </>
    )
}

export default BaseTitle;

const styles = StyleSheet.create({
    title:{
        fontSize:18,
        fontWeight:'bold',
        paddingBottom:16
    },
});