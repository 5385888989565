import axios from 'axios';

export const postInputText = async (data: { prompt: string ,q_no:number})=>{
    try{
        const response = await axios.post(`http://127.0.0.1:8000/app/score`,data);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching score:", error);
        return false;
    }

}

export default postInputText;