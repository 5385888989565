import React, { ReactNode } from 'react';
import { View,Text,StyleSheet } from 'react-native';
import { Header } from "../organisms/Header"

type BaseLayoutProps={
    children:ReactNode,
    title:string,
    status:number,
}

export const BaseLayout : React.FC<BaseLayoutProps> = ({children,title,status})=>{
    return(
        <>
            <Header title={title} status={status}></Header>
            <View style={styles.container}>
                {children}
            </View>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        backgroundColor: '#FFFFFF',
        paddingTop: 40,
        paddingRight: 20,
        paddingBottom: 40,
        paddingLeft: 20,
    },
    text:{
        fontSize:16
    }
});