import React, { ReactNode } from 'react';
import { View, Text, SafeAreaView, StyleSheet } from 'react-native';
import BaseTitle from '../atoms/BaseTitle';
import SubHeading from '../atoms/SubHeading';

type QuestionSchoolProps={
    schoolName: string,
    questionTitle: string,
    year: number |undefined,
}

export const QuestionInfo: React.FC<QuestionSchoolProps> = ({schoolName, questionTitle, year}) => {
    return (
        <View style={styles.container}>
            <BaseTitle title={schoolName + " " + year +"年度"}></BaseTitle>
            <SubHeading title={questionTitle}></SubHeading>
        </View>

    );
};

export default QuestionInfo;

const styles = StyleSheet.create({
    container: {
        marginBottom: 32,
        marginRight: 'auto',
    }
});