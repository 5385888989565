import {Text, View ,Button, StyleSheet, Alert} from 'react-native';
import { HomeLayout } from "../template/HomeLayout"
import BaseButton from '../atoms/BaseButton';
import axios from 'axios';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../App';

type HomeNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Home'>;

type Props ={
    navigation:HomeNavigationProp;
}

export const HomeScreen: React.FC<Props> = ({navigation}) => {
    const handleLogout = async () => {
        try {
          // サーバーにログアウトリクエストを送信
          await axios.post('http://127.0.0.1:8000/accounts/logout/');
          Alert.alert('ログアウト', '正常にログアウトしました');
    
          // ログイン画面にリダイレクト
          navigation.navigate('Login');
        } catch (error) {
          Alert.alert('エラー', 'ログアウトに失敗しました');
        }
      };
    return (
        <>      
            <HomeLayout 
                title=""
                children={
                    <>
                        <BaseButton
                            onPress={() => navigation.navigate('SelectSchools')}
                            title = "答案を採点する"
                        ></BaseButton>
                        <View>
                            <Text
                            style={styles.logoutText}
                            onPress={handleLogout}
                            >
                            ログアウト
                            </Text>
                        </View>
                    </>
                }>
                
            </HomeLayout>
        </>
    );
};

const styles = StyleSheet.create({
    logoutText: {
      color: 'gray',
      fontSize: 14,
      marginTop: 40,
      textAlign: 'center',
    },
  });
  
export default HomeScreen;