import React, { useState } from 'react';
import { TextInput, StyleSheet, View, Text, TextInputProps } from 'react-native';

interface CustomTextAreaProps extends TextInputProps {
    placeholder?: string
    onTextChange: (text: string) => void
}

const TextInputArea: React.FC<CustomTextAreaProps> = ({ placeholder, onTextChange, ...rest }) => {
    const [text, setText] = useState<string>('')

    const handlTextChange = (input: string) => {
        setText(input)
        onTextChange(input)
    }

    return (
        <View style={styles.container}>
            <TextInput
                style={styles.TextInputArea}
                placeholder={placeholder}
                placeholderTextColor= '#777'
                value={text}
                onChangeText={handlTextChange}
                maxLength={100}
                {...rest}
            ></TextInput>
        </View>
    )
}
 
const styles = StyleSheet.create({
    container: {
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: 10,
        width: '100%',
    },
    TextInputArea: {
        height: 40,
        width: '100%',
        borderColor: '#F6F6F6',
        backgroundColor: '#F6F6F6',
        color: '#222', 
        borderWidth: 1,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 16,
        paddingRight: 16,
        textAlignVertical: 'top',
    },
  });
  
export default TextInputArea