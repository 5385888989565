import React, { useState } from 'react';
import { StyleSheet, View, Text, TextInputProps } from 'react-native';
import Svg, { Path } from 'react-native-svg';

type ResultCircleProps= {
    getPoint: number
    maxPoint: number
}

const ResultCircle: React.FC<ResultCircleProps> = ({ getPoint, maxPoint }) => {
    const radius = 140;
    const centerX = 140;
    const centerY = 140;

    var firstColorAngle = 360 * getPoint / maxPoint;
    var secondColorAngle = 360 - firstColorAngle;

    if(firstColorAngle === 0){
        firstColorAngle = 0.01
        secondColorAngle = 359.99
    } else if(secondColorAngle === 0){
        firstColorAngle = 359.9
        secondColorAngle = 0.1
    }

    const offsetAngle = 90;

    const firstPath = `
        M ${centerX},${centerY}
        L ${centerX + radius * Math.cos(((-offsetAngle) * Math.PI) / 180)}, ${centerY + radius * Math.sin(((-offsetAngle) * Math.PI) / 180)}
        A ${radius},${radius} 0 ${firstColorAngle > 180 ? 1 : 0},1 ${centerX + radius * Math.cos(((-offsetAngle + firstColorAngle) * Math.PI) / 180)}, ${centerY + radius * Math.sin(((-offsetAngle + firstColorAngle) * Math.PI) / 180)}
        Z
    `;

    const secondPath = `
        M ${centerX},${centerY}
        L ${centerX + radius * Math.cos(((-offsetAngle + firstColorAngle) * Math.PI) / 180)}, ${centerY + radius * Math.sin(((-offsetAngle + firstColorAngle) * Math.PI) / 180)}
        A ${radius},${radius} 0 ${secondColorAngle > 180 ? 1 : 0},1 ${centerX + radius * Math.cos(((-offsetAngle + firstColorAngle + secondColorAngle) * Math.PI) / 180)}, ${centerY + radius * Math.sin(((-offsetAngle + firstColorAngle + secondColorAngle) * Math.PI) / 180)}
        Z
    `;

    return (
        <View style={styles.container}>
            <Svg height="280" width="280" viewBox="0 0 280 280">
                <Path d={firstPath} fill="#0B62D4"/>
                <Path d={secondPath} fill="#d9d9d9"/>
            </Svg>
            <View style={styles.innerCircle} />
            <View style={styles.innerTexts}>
                <Text style={styles.getPoint}>{getPoint}点</Text>
                <Text style={styles.maxPoint}> / {maxPoint}点</Text>
            </View>
        </View>
    );
};



const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        marginHorizontal: 'auto',
        marginBottom: 48,
    },
    innerCircle: {
        position: 'absolute',
        width: 230,
        height: 230,
        borderRadius: 115,
        backgroundColor: '#FFF',
    },
    innerTexts: {
        position: 'absolute',
        flexDirection: 'row', 
        alignItems: 'center',
        flex: 1,
    },
    getPoint: {
        fontSize: 36,
        color: "#222",
        fontWeight: 'bold',
    },
    maxPoint: {
        fontSize: 18,
        color: "#AAA"
    }
  });

export default ResultCircle