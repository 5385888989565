import React from 'react';
import { View, StyleSheet,Pressable, Text } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

type ButtonProps={
    onPress:()=>void;
    title:string
}


export const crossButton:React.FC<ButtonProps> =({onPress})=>{
    return(
        <Pressable
            style={styles.buttonStyles}
            onPress={onPress}>
            <Text style={styles.crossButton}>✕</Text>
        </Pressable>
    )
}

export default crossButton;

const styles = StyleSheet.create({
    buttonStyles:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },

    crossButton:{
        fontSize: 20,
    },
});