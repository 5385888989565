import axios from "axios";

export const getQuestions = async (query1: string, query2: string) => {
  try {
    const response = await axios.get(
      `http://127.0.0.1:8000/app/select-question/?${query1}&${query2}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching customers:", error);
    return [];
  }
};

export default getQuestions;
