import React, { ReactNode } from 'react';
import { View, Text, SafeAreaView, StyleSheet } from 'react-native';
import LeftArrow from '../atoms/LeftArrow';
import CrossButton from '../atoms/CrossButton';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from "../../App";



type HeaderProps={
    children?:ReactNode,
    title:string,
    status:number,
}

export const Header: React.FC<HeaderProps> = ({children,title,status}) => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    let buttonComponent;
    if (status === 0) {
        buttonComponent = <CrossButton onPress={() => navigation.navigate('Home')} title='' />;
    } else if (status === 1) {
        buttonComponent = <LeftArrow onPress={() => navigation.goBack()} title='' />;
    }

    return (
        <SafeAreaView>
            <View style={styles.header}>
                {buttonComponent}
                <Text style={styles.title}>{title}</Text>
                <View></View>
                {children}
            </View>
        </SafeAreaView>

    );
};

export default Header;

const styles = StyleSheet.create({
    header: {
        backgroundColor: '#F6F6F6',
        padding: 16,
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        color: '#333333',
        fontSize: 20,
    },
});