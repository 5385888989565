import React from 'react';
import { StyleSheet, Text } from 'react-native';

type SubHeadingProps={
    title:string
}


export const SubHeading:React.FC<SubHeadingProps> =({title})=>{
    return(
        <>
            <Text style={styles.subHeading}>{title}</Text>
        </>
    )
}

export default SubHeading;

const styles = StyleSheet.create({
    subHeading:{
        color: "#777777",
        fontSize:14,
    },
});