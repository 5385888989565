import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet, Button } from 'react-native';
import { BaseLayout } from "../template/BaseLayout"
import BaseTitle from '../atoms/BaseTitle';
import BaseText from '../atoms/BaseText';
import SelectBox from '../organisms/SelectBox';
import BackButton from '../atoms/BackButton';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { useRoute } from '@react-navigation/native';
import { RootStackParamList } from '../../App';
import getYear from '../../api/getYear';
import axios from 'axios';

interface Years {
    year:number;
}

type YearNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Home'>;

type Props ={
    navigation: YearNavigationProp;
}



export const SelectQuestionYearScreen:React.FC<Props> = ({navigation}) => {
    const route = useRoute();
    const { schoolId , schoolName } = route.params  as { schoolId: number; schoolName: string };
    const [years,setYears] = useState<Years[]>([]);

    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const query = `school_id=${schoolId}`
        const fetchYear = async () => {
            try{
                
                const data = await getYear(query);
                if (Array.isArray(data)) {
                    setYears(data);
                    console.log(years)
                } else {
                    setError('年度が登録されておりません。');
                }
    
            } catch (error) {
                console.error("Error fetching customers:", error);
                return [];
            }
        }
        fetchYear();
    
    }, [schoolId]);

    return (
        <>
            <BaseLayout title="答案の選択" status={0}>
                <BaseText>採点する問題を選択してください。</BaseText>
                <BackButton
                    title="学校一覧へ戻る"
                    onPress={() => navigation.goBack()}
                ></BackButton>
                <View style={styles.selectList}>
                    <BaseTitle title={schoolName}></BaseTitle>
                </View>
                {
                    error ? ( <Text>{error}</Text>)
                    :(
                        years.length > 0?(
                            years.map(year=>
                                <SelectBox
                                    key={year.year}
                                    title={`${String(year.year)}年度`}
                                    onPress={() => navigation.navigate(
                                        'SelectQuestions',
                                        { schoolId: schoolId, year: year.year, schoolName: schoolName}
                                    )}
                                ></SelectBox>
                            )
                        ):(
                            // 学校一覧が空の場合のメッセージ
                            <Text style={styles.text}>年度が登録されておりません。</Text>
                        )
                    )
                }
            </BaseLayout>
        </>
    );
};

export default SelectQuestionYearScreen;

const styles = StyleSheet.create({
    title:{
        fontSize:18,
        fontWeight:'bold',
        paddingBottom:16
    },
    selectList:{
        paddingTop:48
    },

    text:{
        fontSize:16
    }
});
