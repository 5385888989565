import React, { ReactNode } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

type SelectBoxProps={
    onPress:()=>void;
    key:number,
    children?:ReactNode,
    title:string,
}

export const SelectBox: React.FC<SelectBoxProps> = ({onPress,title}) => {
    
    return (
        <Pressable
            style={styles.select}
            onPress={onPress}
        >
            <Text style={styles.selectText}>{title}</Text>
            <FontAwesomeIcon style={styles.icon} icon={faChevronRight} size={20} color="#333333" />
        </Pressable>
    );
};

export default SelectBox;

const styles = StyleSheet.create({
    select: {
        backgroundColor: '#FFFFFF',
        width: '100%',
        paddingTop: 16,
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 16,
        fontSize:16,
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor:'#333333'
    },
    selectText:{
        fontSize:16,
        fontWeight:'bold',
        color:'#333333'
    },
    icon :{
        position:'absolute',
        right:24,
    }
});