import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet, Button } from "react-native";
import { BaseLayout } from "../template/BaseLayout";
import BaseText from "../atoms/BaseText";
import BaseButton from "../atoms/BaseButton";
import BackButton from "../atoms/BackButton";
import MediumHeading from "../atoms/MediumHeading";
import TextArea from "../atoms/TextArea";
import QuestionInfo from "../organisms/QuestionInfo";
import QuestionText from "../organisms/QuestionText";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRoute } from "@react-navigation/native";
import { RootStackParamList } from "../../App";
import getQuestionDetails from "../../api/getQuestionDetail";
import postInputText from "../../api/postInputText";

type HomeNavigationProp = NativeStackNavigationProp<RootStackParamList, "Home">;

type Props = {
  navigation: HomeNavigationProp;
};

type QuestionDetails = {
    id: number;
    question_name: string;
    year: number;
    school_name: string;
    question: string;
};

export const InputAnswerScreen:React.FC<Props> = ({navigation}) => {
    const route = useRoute();
    // const { schoolId , schoolName, questionId, questionName, questionText } = route.params as { schoolId: number, schoolName: string, questionId: number, questionName: string, questionText: string };

    const { questionId } = route.params as { questionId: number }
    // console.log("route.params="+route.params)

    const [questionDetails, setQuestionDetails] = useState<QuestionDetails[]>([]);
    const [error, setError] = useState<string | null>(null);

    const [inputText, setInputText] = useState<string>("");

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(questionId)
        const query = `question_id=${questionId}`
        console.log("questionId="+questionId)
        console.log("query="+query)
        const fetchQuestionDetails = async () => {
            try {
                const data = await getQuestionDetails(query);

                if (Array.isArray(data)) {
                    setQuestionDetails(data);
                } else {
                    setError("問題の詳細が存在しません。");
                }
            } catch (err) {
                setError("問題の詳細を取得できません。"); // エラーハンドリング
            }
        }

        fetchQuestionDetails();
    }, [questionId]);

    const handleTextChange = (text: string) => {
        setInputText(text);
    };

    const handleSubmit = async ()=>{
        setLoading(true);
        try{
            const post_data = await postInputText(
                {
                    prompt: inputText,
                    q_no:1
                }
            );
            if (post_data) {
                navigation.navigate('Result',{
                    questionTitle: questionDetails[0].question_name,
                    schoolName: questionDetails[0].school_name,
                    year: questionDetails[0].year,
                    answerText: inputText,
                    answer: post_data.res.answer,
                    advice: post_data.res.advice,
                    score: post_data.res.score,
                    addition:post_data.res.addition,
                    subtraction:post_data.res.subtraction,
                    incomplete_answer:post_data.res.incomplete_answer,
                });
            }
            else{
                setError('データの送信に失敗しました');
            }
        }
        catch (err){
            setError('あなたの答案が送信できません。'); // エラーハンドリング
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
        <BaseLayout title="答案の入力" status={1}>
            {error ? (
                <Text>{error}</Text>
            ) : questionDetails.length > 0 ? (
                questionDetails.map((questionDetail) => (
                <>
                    <QuestionInfo
                        schoolName={questionDetail.school_name}
                        year={questionDetail.year}
                        questionTitle={questionDetail.question_name}
                    ></QuestionInfo>
                    <QuestionText
                        text={questionDetail.question}
                    ></QuestionText>
                </>
            ))
            ) : (
            // 問題一覧が空の場合のメッセージ
            <Text style={styles.text}>問題が登録されておりません。</Text>
            )}

            <View style={styles.inputText}>
                <MediumHeading title={"あなたの答案"}></MediumHeading>
                <TextArea
                    maxLength={100}
                    placeholder="ここにあなたの答案を入力してください。"
                    onTextChange={handleTextChange}
                ></TextArea>
            </View>
            <BaseButton
                onPress={handleSubmit}
                title = {loading ? '採点中...' :"採点する"}
            ></BaseButton>

        </BaseLayout>
            </>
        );
    };

    const styles = StyleSheet.create({
        inputText: {
            width: '100%',
        },
        text:{
            fontSize:16
        }
    });
