import React from 'react';
import { View, StyleSheet,Pressable, Text } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

type ButtonProps={
    onPress:()=>void;
    title:string
}


export const LeftArrow:React.FC<ButtonProps> =({onPress})=>{
    return(
        <Pressable
            style={styles.buttonStyles}
            onPress={onPress}>
            <View style={styles.arrowContainer}>
                <View style={styles.arrowLine} />
                <View style={styles.arrowHead} />
            </View>
        </Pressable>
    )
}

export default LeftArrow;

const styles = StyleSheet.create({
    buttonStyles:{
        display:'flex',
        flexDirection:'row',
        alignItems:'center'
    },

    arrowContainer: {
        position: 'relative',
        width: 16,  // 1em相当の幅
        height: 2,  // 0.1em相当の高さ
    },
    arrowLine: {
        width: '100%',
        height: 2,
        backgroundColor: '#333',
    },
    arrowHead: {
        position: 'absolute',
        top: -4,
        width: 10,  // 0.65em相当の幅
        height: 10,  // 0.65em相当の高さ
        borderTopWidth: 2,
        borderLeftWidth: 2,
        borderColor: '#333',
        transform: [{ rotate: '-45deg' }],
    },
});