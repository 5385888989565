import axios from 'axios';

export const getQuestionDetails = async (query: string)=>{
    try{
        const response = await axios.get(`http://127.0.0.1:8000/app/select-question-details/?${query}`);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error("Error fetching customers:", error);
        return [];
    }

}

export default getQuestionDetails;