import React from 'react';
import { StyleSheet, Text, View, StyleProp, TextStyle, } from 'react-native';

type AdjustPointProps={
    index: number
    status: string
    adjustTitle?: string
    point?: number
    explanation?: string
    CustomStyleStatus?: StyleProp<TextStyle>
    CustomStyleAdjustText?: StyleProp<TextStyle>; 
}


export const AdjustPoint:React.FC<AdjustPointProps> =({ index, status, adjustTitle, point, explanation, CustomStyleStatus, CustomStyleAdjustText })=>{
    return(
        <View style={styles.container}>
            <View style={styles.top}>
                <View style={styles.title}>
                    <Text style={[styles.adjust, CustomStyleStatus]}>{status}</Text>
                    <Text style={ CustomStyleAdjustText }>{adjustTitle}{index}</Text>
                </View>
                <Text style={CustomStyleAdjustText}>{point ? status+point: 'あり'}</Text>
            </View>
            <Text style={styles.explanation}>{explanation}</Text>
        </View>
    )
}

export default AdjustPoint;

const styles = StyleSheet.create({
    container: {
        fontSize: 14,
        backgroundColor: "#F6F6F6",
        width: '100%',
        padding: 16,
        marginBottom: 8,
    },
    top: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    adjust: {
        width: 20,
        height: 20,
        lineHeight: 18,
        borderRadius: 10,
        color: '#FFF',
        textAlign: 'center',
        marginRight: 5,
    },
    explanation: {
        marginTop: 8,
    }
});