import { ScrollView, Text, View ,Button, StyleSheet } from 'react-native';
import { useRoute } from '@react-navigation/native';
import { BaseLayout } from '../template/BaseLayout';
import MediumHeading from '../atoms/MediumHeading';
import Tab from '../organisms/Tab';
import QuestionInfo from '../organisms/QuestionInfo';
import ResultCircle from '../atoms/ResultCircle';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { RootStackParamList } from '../../App';
import { Dimensions } from 'react-native';
import { Scoring } from '../../App';

type HomeNavigationProp = NativeStackNavigationProp<RootStackParamList, 'Home'>;

type Props ={
    navigation:HomeNavigationProp;
}

const { height: screenHeight } = Dimensions.get('window');

export const ResultScreen: React.FC<Props> = ({navigation}) => {
    const route = useRoute();
    const {
        questionTitle,
        schoolName,
        year,
        answerText,
        answer,
        advice,
        score,
        addition,
        subtraction,
        incomplete_answer,
    } = route.params  as {
        questionTitle: string,
        schoolName: string,
        year:number,
        answerText: string,
        answer: string,
        advice: string,
        score: number,
        addition:Scoring[],
        subtraction:Scoring[],
        incomplete_answer:string,
    };

    return (
        <>
            <ScrollView contentContainerStyle={styles.scrollView}>
                <BaseLayout title="採点結果" status={1}>
                    <QuestionInfo
                        schoolName={schoolName}
                        year={year}
                        questionTitle={questionTitle}
                    ></QuestionInfo>
                    <ResultCircle
                        getPoint={score}
                        maxPoint={12}
                    ></ResultCircle>
                    <View style={styles.answer}>
                        <MediumHeading
                            title="あなたの答案"
                        ></MediumHeading>
                        <Text>{answerText}</Text>
                    </View>
                    <Tab
                        advice={advice}
                        answer={answer}
                        addition={addition}
                        subtraction={subtraction}
                        incomplete_answer={incomplete_answer}
                    ></Tab>
                </BaseLayout>
            </ScrollView>
        </>
    );
};

export default ResultScreen;

const styles = StyleSheet.create({
    scrollView: {
        flexGrow: 1,
    },
    container: {
        minHeight: screenHeight,
    },
    answer: {
        marginBottom: 48,
    },
});