import React, { ReactNode } from 'react';
import { View, Text, SafeAreaView, StyleSheet } from 'react-native';
import BaseTitle from '../atoms/BaseTitle';
import SubHeading from '../atoms/SubHeading';
import MediumHeading from '../atoms/MediumHeading';

type QuestionTextProps={
    text: string,
}


export const QuestionText: React.FC<QuestionTextProps> = ({text}) => {
    return (
        <View style={styles.container}>
            <MediumHeading title={"設問"}></MediumHeading>
            <Text>{text}</Text>
        </View>        

    );
};

export default QuestionText;

const styles = StyleSheet.create({
    container: {
        marginBottom: 48,
        width: '100%',
    }
});