import React, { ReactNode } from 'react';
import { StyleSheet, Text } from 'react-native';

type BaseTextProps={
    children?:ReactNode
}


export const BaseText:React.FC<BaseTextProps> =({children})=>{
    return(
        <>
            <Text style={styles.text}>{children}</Text>
        </>
    )
}

export default BaseText;

const styles = StyleSheet.create({
    text:{
        fontSize:16,
        paddingBottom:16
    },
});