import React, { useState, useEffect } from "react";
import { Text, View, StyleSheet } from "react-native";
import { BaseLayout } from "../template/BaseLayout";
import BaseTitle from '../atoms/BaseTitle';
import BaseText from '../atoms/BaseText';
import SelectBox from "../organisms/SelectBox";
import BackButton from '../atoms/BackButton';
import getQuestions from "../../api/getQuestion";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { useRoute } from '@react-navigation/native';
import { RootStackParamList } from "../../App";

interface Questions {
  id: number;
  question_name: string;
}

type Props = {
  navigation: NativeStackNavigationProp<RootStackParamList, "SelectQuestions">;
};

export const SelectQuestionsScreen: React.FC<Props> = ({ navigation }) => {

  const route = useRoute();
  const { schoolId , year , schoolName } = route.params  as { schoolId: number; year: number, schoolName: string };

  const [questions, setQuestions] = useState<Questions[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const query1 = `year=${year}`
    const query2 = `school_id=${schoolId}`
    const fetchQuestion = async () => {
        try{
            
            const data = await getQuestions(query1, query2);
            if (Array.isArray(data)) {
                setQuestions(data);
                console.log(questions)
            } else {
                setError('問題が登録されておりません。');
            }

        } catch (error) {
            console.error("Error fetching customers:", error);
            return [];
        }
    }
    fetchQuestion();

  }, [year]);

  return (
    <>
      <BaseLayout title="答案の選択" status={0}>
        <BaseText>採点する問題を選択してください。</BaseText>
        <BackButton
            title="出題年度一覧へ戻る"
            onPress={() => navigation.goBack()}
        ></BackButton>
        <View style={styles.selectList}>
            <BaseTitle title={`${schoolName}  ${String(year)}年度`}></BaseTitle>
        </View>
        {error ? (
          <Text>{error}</Text>
        ) : questions.length > 0 ? (
          questions.map((question) => (
            <SelectBox
              key={question.id}
              title={question.question_name}
              onPress={() =>
                navigation.navigate("InputAnswer", {
                  questionId: question.id,
                })
              }
            ></SelectBox>
          ))
        ) : (
          // 問題一覧が空の場合のメッセージ
          <Text style={styles.text}>問題が登録されておりません。</Text>
        )}
      </BaseLayout>
    </>
  );
};

export default SelectQuestionsScreen;

const styles = StyleSheet.create({
  selectList:{
    paddingTop:48
  },
  text: {
    fontSize: 16,
  },
});
