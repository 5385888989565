import React, { useState } from 'react';
import { View, Text, Pressable, StyleSheet } from 'react-native';
import ResultDetail from './AnswerResult';
import Advice from './Advice';
import BaseText from '../atoms/BaseText';
import { Scoring } from '../../App';


type TabProps={
    answer:string,
    advice:string,
    addition:Scoring[],
    subtraction:Scoring[],
    incomplete_answer:string,
}


const Tab: React.FC<TabProps> = ({
        answer,
        advice,
        addition,
        subtraction,
        incomplete_answer
    }) => {
    const [activeTab, setActiveTab] = useState<number>(1);

    return (
    <View style={styles.container}>
        <View style={styles.tabContainer}>
            <Pressable
                style={[styles.tabButton, activeTab === 1 ? styles.tabButtonActive : null]}
                onPress={() => setActiveTab(1)}
            >
                <Text style={[styles.tabButtonText, activeTab === 1 ? styles.tabButtonTextActive : null]}>
                    採点の結果
                </Text>
            </Pressable>

            <Pressable
                style={[styles.tabButton, activeTab === 2 ? styles.tabButtonActive : null]}
                onPress={() => setActiveTab(2)}
                >
                <Text style={[styles.tabButtonText, activeTab === 2 ? styles.tabButtonTextActive : null]}>
                    アドバイス
                </Text>
            </Pressable>
        </View>

        {activeTab === 1 && (
        <View>
            <ResultDetail
                answerText={answer}
                additions={addition}
                subtractions={subtraction}
                incomplete_answers={incomplete_answer}
            ></ResultDetail>
        </View>
        )}

        {activeTab === 2 && (
        <View>
            <Advice adviceText={advice}></Advice>
        </View>
        )}
    </View>
    );
};

export default Tab;

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    tabContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
    },
    tabButton: {
        width: '50%',
    borderBottomWidth: 2,
        borderColor: 'transparent', // 透明な境界線
        borderBottomColor: '#777',
        marginBottom:2,
        paddingBottom: 8,
        paddingHorizontal: 20,
    },
    tabButtonActive: {
        borderBottomColor: '#007BFF', // アクティブなタブの境界線は青色
        borderBottomWidth:2,
        paddingBottom: 6, // アクティブなタブは少し境界線に近づける
        borderColor:"#007BFF",
    },
    tabButtonText: {
        color: '#666', // 暗めのグレーテキスト
        fontSize: 16,
        textAlign: 'center',
    },
    tabButtonTextActive: {
        color: '#007BFF', // アクティブなタブのテキストも青色
        fontWeight: 'bold',
    },
});